<template>
  <div id="app">
    <div class="body fixed mobile">
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <!-- <div class="spinner"><div><div></div></div></div> -->
      <!-- Header -->
      <section class="header point_history">
        <a class="icon iconButton third" @click.prevent="goBackTo">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
        <span class="fL">交易紀錄</span>
        <div class="icon iconButton"></div>
      </section>
      <!-- Main -->
      <section class="main point_history">
        <!-- Tabs -->
        <div class="tabs line fM rounded">
          <a v-if="enableConsumptionRecord" class="tab flexV width center active" @click.prevent>
            <span class="fS">消費紀錄</span>
          </a>
          <div v-if="enableConsumptionRecord" class="lineV"></div>
          <a
            class="tab flexV width center"
            @click.prevent="$router.push('/record')"
          >
            <span class="fS">點數紀錄</span>
          </a>
          <div class="lineV"></div>
          <a
            class="tab flexV width center"
            @click.prevent="$router.push('/point-list')"
          >
            <span class="fS">點數到期日</span>
          </a>
        </div>
        <!-- Filter -->
        <div class="filter flexH width">
          <a
            class="flexH width middle rounded"
            @click.prevent="timeFilterOpen = true"
          >
            <span class="fM">{{ displayedTimeFilterOption }}</span>
            <div class="icon third">
              <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
            </div>
          </a>
        </div>
        <!-- 紀錄 -->
        <div class="records flexV width padding">
          <a
            class="record flexH width margin"
            v-for="(order, index) in displayedOrders"
            :key="'order' + index"
            @click.prevent="showOrderDetail(order.orderId)"
          >
            <div
              v-if="order.paymentMethod === 'offline'"
              class="icon type black"
            >
              <font-awesome-icon
                icon="fa-solid fa-money-bill-wave"
                size="xl"
              ></font-awesome-icon>
            </div>
            <div
              v-else-if="
                getPaymentImage(order.paymentService, order.paymentType)
              "
              class="img type"
            >
              <img
                :src="getPaymentImage(order.paymentService, order.paymentType)"
              />
            </div>
            <div v-else class="icon type black">
              <font-awesome-icon
                icon="fa-solid fa-credit-card"
                size="xl"
              ></font-awesome-icon>
            </div>
            <div class="flexV width">
              <span class="fM">{{ order.storeName }}</span>
              <span class="fS gray">{{ displayOrderTime(order) }}</span>
              <span v-if="order.status !== 'done'" class="fS error">
                已取消
              </span>
            </div>
            <div class="flexV end">
              <span v-if="order.paymentAmount" class="fM"
                >NT${{ order.paymentAmount }}</span
              >
              <div v-if="order.points || (order.amount && !order.paymentAmount && !order.pointAmount)" class="flexH">
                <div class="img"><img :src="pointImage" /></div>
                <span class="fM primary">{{ order.points || order.amount}}</span>
              </div>
            </div>
            <div class="icon minor">
              <font-awesome-icon
                icon="fa-solid fa-chevron-right"
              ></font-awesome-icon>
            </div>
          </a>
        </div>
      </section>
      <section class="footer point_history">
        <a class="button rounded" @click.prevent="$router.push('/scanner')">
          <div class="icon">
            <img src="@/assets/icon/scanner.svg" />
          </div>
          <span class="fM">使用 {{ pointName }}</span>
        </a>
      </section>
      <!-- Popup (時間區間) -->
      <FilterPopup
        v-if="timeFilterOpen"
        @closePopup="timeFilterOpen = false"
        v-slot="pop"
        :popTime="true"
      >
        <section class="popHeader">
          <span class="fL">時間區間</span>
        </section>
        <section class="popMain">
          <!-- Tabs -->
          <div class="tabs rounded fS">
            <a
              :class="[
                'tab',
                'rounded',
                { active: timeFilterOption === 'Weekly' },
              ]"
              @click.prevent="changeTimeFilterOption('Weekly')"
            >
              <span>近一週</span>
            </a>
            <a
              :class="[
                'tab',
                'rounded',
                { active: timeFilterOption === 'Monthly' },
              ]"
              @click.prevent="changeTimeFilterOption('Monthly')"
            >
              <span>近一個月</span>
            </a>
            <a
              :class="[
                'tab',
                'rounded',
                { active: timeFilterOption === 'Yearly' },
              ]"
              @click.prevent="changeTimeFilterOption('Yearly')"
            >
              <span>近一年</span>
            </a>
            <a
              :class="[
                'tab',
                'rounded',
                { active: timeFilterOption === 'Customize' },
              ]"
              @click.prevent="changeTimeFilterOption('Customize')"
            >
              <span>自訂時間</span>
            </a>
          </div>
          <!-- Date -->
          <div class="input rounded" v-show="timeFilterOption == 'Customize'">
            <!-- class: fake / error -->
            <input
              :class="['fM', 'date', { error: selectStartErr }]"
              type="date"
              name="startDate"
              v-model="selectStart"
              @change="selectStartChange"
            />
            <!-- <input class="fM time" type="time" name="startTime"> -->
            <span class="fL">-</span>
            <input
              :class="['fM', 'date', { error: selectEndErr }]"
              type="date"
              name="endDate"
              v-model="selectEnd"
              @change="selectEndChange"
            />
          </div>
          <span v-if="timeFilterOption==='Customize' && (selectStartErr || selectEndErr)" class="error">
            {{ selectTimeErrMsg }} 
          </span>
        </section>
        <section class="popFooter">
          <a
            class="button rounded cancel white"
            @click.prevent="pop.close"
            ><span class="fM">取消</span></a
          >
          <a
            class="button rounded submit"
            @click.prevent="timeFilterConfirm(); pop.close();"
            ><span class="fM">確認</span></a
          >
        </section>
      </FilterPopup>
      <!-- Popup (交易明細) -->
      <section
        id="popDetail"
        class="popup popDetail point_history"
        @click.prevent="orderDetailHide()"
      >
        <div class="popBody" @click.stop>
          <a class="close" @click.prevent="orderDetailHide()"
            ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
          ></a>
          <section class="popHeader">
            <span class="fL">交易明細</span>
          </section>
          <section class="popMain">
            <div class="flexH width middle cost">
              <span v-if="orderDetail.paymentAmount" class="fXL bold"
                >NT${{ orderDetail.paymentAmount }}</span
              >
              <fragment v-if="orderDetail.points || (orderDetail.amount && !orderDetail.paymentAmount && !orderDetail.point)">
                <img :src="pointImage" />
                <span class="fXL bold primary">{{ orderDetail.points || orderDetail.amount }}</span>
              </fragment>
            </div>
            <div class="flexH width between">
              <span class="fM gray">交易時間</span>
              <span class="fM">{{ displayOrderTime(orderDetail) }}</span>
            </div>
            <div class="flexH width between">
              <span class="fM gray">支付方式</span>
              <span class="fM">{{ displayPaymentType(orderDetail) }}</span>
            </div>
            <div v-if="orderDetail.paymentAmount" class="flexH width between">
              <div
                v-if="orderDetail.paymentMethod === 'offline'"
                class="img store_type"
              >
                <font-awesome-icon
                  icon="fa-solid fa-money-bill-wave"
                  size="xl"
                ></font-awesome-icon>
              </div>
              <div
                v-else-if="
                  getPaymentImage(orderDetail.paymentService, orderDetail.paymentType)
                "
                class="img store_type"
              >
                <img
                  :src="getPaymentImage(orderDetail.paymentService, orderDetail.paymentType)"
                />
              </div>
              <div v-else class="img store_type">
                <font-awesome-icon
                  icon="fa-solid fa-credit-card"
                  size="xl"
                ></font-awesome-icon>
              </div>
              <span class="fM gray width">{{ displayPaymentType(orderDetail) }}</span>
              <span class="fM">{{ orderDetail.paymentAmount }}</span>
            </div>
    
            <template v-if="pointOrderDetail && pointOrderDetail.records && pointOrderDetail.records.length">
              <!-- workaround:用giverEntityType決定要不要顯示，避免取消訂單時點數記錄重複顯示 -->
              <div v-for="(rec, index) in pointOrderDetail.records" :key="'point_' + index" class="flexH width between" v-show="rec.giverEntityType==='U'">
                <div class="img store_type">
                  <img v-if="rec.logo" :src="rec.logo">
                  <img v-else :src="pointImage">
                </div>
                <span class="fM gray width">{{ rec.title || pointName }}</span>
                <span class="fM">{{ rec.ledgerAmount }}</span>
              </div>
            </template>
            <template v-else-if="orderDetail.pointAmount">
              <div class="flexH width between">
                <div class="img store_type">
                  <img :src="pointImage">
                </div>
                <span class="fM gray width">{{ orderDetail.pointName }}</span>
                <span class="fM">{{ orderDetail.pointAmount }}</span>
              </div>
            </template>
            
            
            <div class="flexH width between">
              <span class="fM gray">交易特店</span>
              <span class="fM">{{ orderDetail.storeName }}</span>
            </div>
            <div class="flexH width between">
              <span class="fM gray">交易編號</span>
              <span class="fM">{{ orderDetail.orderId }}</span>
            </div>
            <div class="flexH width between">
              <span class="fM gray">交易狀態</span>
              <span class="fM">{{
                displayOrderStatus(orderDetail.status)
              }}</span>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import FilterPopup from '@/components/FilterPopup.vue';

export default {
  name: 'PaymentRecord',
  components: { FilterPopup },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      paymentOrders: {},
      originPaymentOrders: {},
      timeFilterStart: null,
      timeFilterEnd: null,
      timeFilterOption: 'Monthly',
      displayedTimeFilterOption: '近一個月',
      selectStart: this.tsToDate(
        Math.floor(Date.now() / 1000) - 1 * 60 * 60 * 24 * 30
      ),
      selectEnd: this.tsToDate(Math.floor(Date.now() / 1000)),
      selectStartErr: false,
      selectEndErr: false,
      selectTimeErrMsg: '',
      orderDetail: {},
      timeFilterOpen: false,
      pointOrderDetail: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    if (from.path === '/profile') {
      to.meta.parent = '/profile';
    } else if (from.path === '/' || from.path === '/record' || from.path === '/point-list' || from.path === '/payment-record') {
      to.meta.parent = localStorage.getItem('parentPath') || '/home';
    } else {
      to.meta.parent = '/home';
    }
    localStorage.setItem('parentPath', to.meta.parent);
    
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        next('/error');
      }
    });
  },
  created() {
    this.getPaymentOrders()
      .then((getPaymentOrdersRes) => {
        this.paymentOrders = JSON.parse(JSON.stringify(getPaymentOrdersRes));
        this.originPaymentOrders = JSON.parse(
          JSON.stringify(getPaymentOrdersRes)
        );
      })
      .catch((err) => {
        console.log('err: ', err);
        this.router.push('/error');
      });
  },
  computed: {
    ...mapState(['user']),
    displayedOrders() {
      return this.paymentOrders.orders?.length ? this.paymentOrders.orders : [];
    },
    enableConsumptionRecord() {
      return process.env.VUE_APP_DISABLE_CONSUMPTION_RECORD?.trim() === 'true' ? false : true;
    }, 
    pointImage() {
      return process.env.VUE_APP_POINT_IMAGE_PATH?.trim() ? require(`@/assets/${process.env.VUE_APP_POINT_IMAGE_PATH}`) : require("@/assets/icon/point.svg");
    }, 
    pointName() {
      return process.env.VUE_APP_POINT_NAME?.trim() ? process.env.VUE_APP_POINT_NAME?.trim() : '紅利桃子';
    }, 
    
  },
  mounted() {
  },
  methods: {
    goBackTo() {
      let parent = this.$route.meta.parent || '/home';
      this.$router.push(parent);
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD HH:mm:ss');
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD');
    },
    callPaymentOrdersAPI(start, end) {
      let endTime = end || Math.floor(Date.now() / 1000);
      let startTime = start || this.getStartTs(endTime, 30);

      var config = {
        method: 'get',
        url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/members/${this.user.userId}/orders?startTs=${startTime}&endTs=${endTime}`,
        headers: {},
      };
      return this.$http(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getPaymentOrders(start, end) {
      return this.callPaymentOrdersAPI(start, end)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log('err:', err);
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
    timeFilterConfirm() {
      if (this.timeFilterOption == 'Customize') {
        this.selectStartErr = this.selectStart ? false : true;
        this.selectEndErr = this.selectEnd ? false : true;
        if (this.selectStartErr || this.selectEndErr) return;
      }
      this.changeTimeFilterValue();
      let start = this.timeFilterStart;
      let end = this.timeFilterEnd;

      this.getPaymentOrders(start, end)
        .then((getPaymentOrdersRes) => {
          this.paymentOrders = JSON.parse(JSON.stringify(getPaymentOrdersRes));
          this.originPaymentOrders = JSON.parse(
            JSON.stringify(getPaymentOrdersRes)
          );
        })
        .catch((err) => {
          console.log('err: ', err);
          this.$router.push('/error');
        });
    },
    changeTimeFilterOption(option) {
      this.timeFilterOption = option;
    },
    changeTimeFilterValue() {
      switch (this.timeFilterOption) {
        case 'Weekly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 7);
          this.displayedTimeFilterOption = '近一週';
          break;
        case 'Monthly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 30);
          this.displayedTimeFilterOption = '近一個月';
          break;
        case 'Yearly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 365);
          this.displayedTimeFilterOption = '近一年';
          break;
        case 'Customize':
          this.timeFilterEnd =
            this.dateEndToTs(this.selectEnd) > Date.now() / 1000
              ? Date.now() / 1000
              : this.dateEndToTs(this.selectEnd);
          this.timeFilterStart = this.dateStartToTs(this.selectStart);
          this.displayedTimeFilterOption = '自訂時間';
          break;
        default:
          break;
      }
    },
    dateStartToTs(date) {
      return new Date(date).setHours(0, 0, 0, 0) / 1000;
    },
    dateEndToTs(date) {
      return new Date(date).setHours(23, 59, 59, 999) / 1000;
    },
    selectStartChange() {
      this.selectStartErr = this.selectStart ? false : true;
      this.selectEndErr = this.selectEnd ? false : true;
      
      if (this.selectStartErr) {
        this.selectTimeErrMsg = '開始日期/結束日期為必填';
        return;
      }
      if (new Date(this.selectStart) > new Date(this.selectEnd)) {
        this.selectStartErr = true;
        this.selectTimeErrMsg = '開始日期須小於結束日期';
      }
    },
    selectEndChange() {
      this.selectStartErr = this.selectStart ? false : true;
      this.selectEndErr = this.selectEnd ? false : true;
       if (this.selectEndErr) {
        this.selectTimeErrMsg = '開始日期/結束日期為必填';
        return;
      }
      if (new Date(this.selectStart) > new Date(this.selectEnd)) {
        this.selectEndErr = true;
        this.selectTimeErrMsg = '開始日期須小於結束日期';
      }
    },
    showOrderDetail(id) {
      popDetail.style.display = 'flex';
      this.orderDetail = this.displayedOrders.find(
        (order) => order.orderId === id
      );
      this.getPointOrderDetail(this.orderDetail.merchantOrderId);
    },
    orderDetailHide() {
      popDetail.style.display = 'none';
      this.pointOrderDetail = {};
    },
    getPaymentImage(service, type) {
      let icon = null;
      let method = `${service}-${type}`;
      switch (method) {
        case 'tappay-apple':
          icon = require('@/assets/icon/p-applepay.svg');
          break;
        case 'tappay-google':
          icon = require('@/assets/icon/p-googlepay.svg');
          break;
        case 'tappay-line':
        case 'linepay-linepay':
          icon = require('@/assets/icon/p-linepay.svg');
          break;
        case 'easywallet-easywallet':
          icon = require('@/assets/icon/p-easywallet.svg');
          break;
        case 'tappay-jko':
          icon = require('@/assets/icon/p-jkopay.svg');
          break;
        case 'taiwanpay-twqrp':
          icon = require('@/assets/icon/p-taiwanpay.svg');
          break;
        default:
          if (service === null && type === null) {
            icon = require('@/assets/icon/point.svg');
          } else {
            icon = null;
          }
          break;
      }
      return icon;
    },
    displayPaymentType(order) {
      const {
        paymentService,
        paymentType,
        paymentMethod,
        amount,
        paymentAmount,
        pointAmount,
      } = order;
      if (paymentMethod === 'offline') return '現場支付';
      if (amount === pointAmount) return '點數';
      // 20231116 workaround for 2022舊point_orders被移除
      if (amount && !pointAmount && !paymentAmount ) return '點數';
      let method = `${paymentService}-${paymentType}`;
      switch (method) {
        case 'tappay-apple':
          return 'Apple Pay';
        case 'tappay-google':
          return 'Google Pay';
        case 'tappay-line':
        case 'linepay-linepay':
          return 'Line Pay';
        case 'easywallet-easywallet':
          return '悠遊付';
        case 'tappay-jko':
          return '街口支付';
        case 'taiwanpay-twqrp':
          return '台灣 Pay';
        default:
          return '信用卡';
      }
    },
    displayOrderStatus(status) {
      return status === 'done' ? '已付款' : '已取消';
    },
    displayOrderTime(order) {
      const time =
        order.status === 'cancelled' ? order.cancelledAt : order.createdAt;
      return this.tsToDatetime(time);
    },
    getStartTs(endTs, days) {
      return (
        new Date(endTs * 1000).setHours(0, 0, 0, 0) / 1000 -
        1 * 60 * 60 * 24 * days
      );
    },
    getPointOrderDetail (merchantOrderId) {
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/orders/${merchantOrderId}`,
        headers: {},
      };
      const self = this;
      return this.$http(config)
        .then(function (response) {
          // TBD：有多期別點數的呈現
          self.pointOrderDetail = response.data;
          return response;
        })
        .catch(function (error) {
          console.log(error);
          // self.pointOrderDetail =  {
          //     "total": 2,
          //     "points": 1,
          //     "transactionId": "88b629737aa09b84bcb688cf67158eca749e6203",
          //     "transactionStatus": "success",
          //     "transactionType": "Order-Cancellation",
          //     "transactionTs": 1660576024,
          //     "records": [
          //         {
          //             "pointAmount": 90,
          //             "entryType": "D",
          //             "note": "於店家佳食米屋韓式手海苔飯捲消費",
          //             "transactionId": 6775,
          //             "transactionEntityId": null,
          //             "transactionTs": 1699248973,
          //             "transactionType": "Consumption-Discount",
          //             "merchantOrderId": 9032,
          //             "logo": "https://storage.googleapis.com/appointed_store_images/tagImages/leaf.svg",
          //             "customPublisher": "tycard",
          //             "title": "限定點",
          //             "options": null
          //         },
          //         {
          //             "pointAmount": 10,
          //             "entryType": "D",
          //             "note": "於店家佳食米屋韓式手海苔飯捲消費",
          //             "transactionId": 6775,
          //             "transactionEntityId": null,
          //             "transactionTs": 1699248973,
          //             "transactionType": "Consumption-Discount",
          //             "merchantOrderId": 9032,
          //             "logo": "",
          //             "customPublisher": null,
          //             "title": "",
          //             "options": null
          //         }
          //     ]
          // }
        });
    }
  },
};
</script>
